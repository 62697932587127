<template>
  <div>
    <el-row class="tac">
      <el-col :span="20">
        <div style="margin-left: 20px;text-align: center;margin-bottom: 40px;">
          <img
            style="width: 72px;height: 72px;object-fit: cover;"
            src="/img/denglu.png"
            alt=""
          />
          <p>阳光明媚</p>
        </div>
        <el-menu
          style="width: 240px;"
          default-active="2"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          background-color="#fdfeff"
          text-color="#333333"
          active-text-color="#24a2aa"
        >
          <el-menu-item index="1">
            <i class="el-icon-menu"></i>
            <span slot="title">报废汽车查看</span>
          </el-menu-item>
          <el-menu-item index="2">
            <i class="el-icon-menu"></i>
            <span slot="title">零配件订单</span>
          </el-menu-item>
          <el-menu-item index="3">
            <i class="el-icon-menu"></i>
            <span slot="title">大宗商品申请查看</span>
          </el-menu-item>
          <el-menu-item index="4">
            <i class="el-icon-setting"></i>
            <span slot="title">密码设置</span>
          </el-menu-item>
          <el-menu-item index="5">
            <i class="el-icon-setting"></i>
            <span slot="title">收货地址维护</span>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    }
  }
};
</script>

<style></style>
