<template>
  <div style="background-color: #f8fcff;">
    <el-card
      style="width: 1180px;margin: 20px auto;display: flex;"
      class="box-card"
    >
      <div style="float: left;">
        <leftNav></leftNav>
      </div>
      <div style="float: left;margin-left: 30px;">
        <h2 style="margin: 0 0 30px 10px;">收货地址维护</h2>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="收货人">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input
              style="float: left;width: 180px;margin-right: 50px;"
              v-model="form.name"
            ></el-input>
            <span style="float: left;margin-right: 20px;">备选号码</span>
            <el-input
              style="float: left;width: 180px;"
              v-model="form.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="收货地址">
            <el-input type="textarea" v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item label="邮编">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item>
            <p>设为默认收货地址</p>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">添加新地址</el-button>
            <el-button>清空</el-button>
          </el-form-item>
        </el-form>

        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="date" label="收货人" width="100">
          </el-table-column>
          <el-table-column prop="name" label="收货地址" width="180">
          </el-table-column>
          <el-table-column prop="address" label="邮编" width="180">
          </el-table-column>
          <el-table-column prop="address" label="手机/电话" width="180">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
              <el-button type="text" size="small">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import leftNav from "@/components/LeftNav/index.vue";
export default {
  name: "adressMaintain",
  components: {
    leftNav
  },
  data() {
    return {
      form: {
        name: "",
        type: [],
        desc: ""
      },
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        },
        {
          date: "2016-05-08",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        },
        {
          date: "2016-05-06",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        }
      ]
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    }
  }
};
</script>

<style></style>
